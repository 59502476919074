import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousel = document.querySelector('.site-testimonials__carousel');

  if (carousel) {
    const slides = carousel.querySelectorAll('.swiper-slide');
    const args = {
      pagination: {
        el: carousel.querySelector('.swiper-pagination'),
        clickable: true,
      },
    };

    if (1 < slides.length) {
      args.loop = true;
      args.watchOverflow = false;
    } else {
      args.watchOverflow = true;
    }

    new Swiper(carousel, args);
  }
});
