document.addEventListener('DOMContentLoaded', () => {
  const leaves = document.querySelectorAll('.animatedLeaves');

  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('appear');
        entry.target.classList.remove('disappear');
      } else {
        entry.target.classList.remove('appear');
        entry.target.classList.add('disappear');
      }
    });
  };

  // ------------------ Create intersection observer
  const observer = new IntersectionObserver(handleIntersect, {
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No margin
    threshold: 0.5, // 50% of the element is in the viewport
  });

  // ------------------ Observe the leaf
  leaves.forEach((leaf) => {
    observer.observe(leaf);
  });
});
