import $ from 'jquery';
import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';
import { _x } from '@wordpress/i18n';

let fieldsAreInitialized = false;

function initSelects() {
  const selects = document.querySelectorAll(
    'select.custom-select, .gform_wrapper select'
  );

  if (0 < selects.length) {
    selects.forEach((select) => {
      new TomSelect(select, {
        create: false,
      });
    });
  }
}

function initFacetSelects() {
  const selects = document.querySelectorAll('select.facetwp-per-page-select');

  if (0 < selects.length) {
    selects.forEach((select) => {
      new TomSelect(select, {
        create: false,
      });
    });
  }
}

function changeFormLabel(label, input) {
  if ('' !== input.value) {
    label.classList.add('active');
  }

  label.addEventListener('click', function () {
    input.focus();
  });

  input.addEventListener('focus', function () {
    label.classList.add('active');
  });

  input.addEventListener('focusout', function () {
    if (!input.value || '(___) ___-____' === input.value) {
      label.classList.remove('active');
    }
  });
}

function changeFileUpload(formID) {
  // select right file uploads
  let singleFileUploads;
  let multipleFileUploads;
  if (formID) {
    const form = document.querySelector('form[data-formid="' + formID + '"');
    singleFileUploads = form.querySelectorAll('.gfield--fileupload-single');
    multipleFileUploads = form.querySelectorAll('.gform_fileupload_multifile');
  } else {
    singleFileUploads = document.querySelectorAll('.gfield--fileupload-single');
    multipleFileUploads = document.querySelectorAll(
      '.gform_fileupload_multifile'
    );
  }
  // single file uploads
  if (0 < singleFileUploads.length) {
    singleFileUploads.forEach(function (fileUpload) {
      const input = fileUpload.querySelector('input[type="file"]');
      const label = fileUpload.querySelector('.gfield_label');
      const noFileLabel = _x('No file selected', 'file upload', 'vtx');
      const icon = document.createElement('span');
      const filePlaceholder = document.createElement('p');
      filePlaceholder.classList.add('file-placeholder');
      filePlaceholder.textContent = input.value ? input.value : noFileLabel;
      icon.classList.add('icon-ajouter-cv');
      label.appendChild(icon);
      label.after(filePlaceholder);

      input.addEventListener('change', function () {
        filePlaceholder.textContent = input.value ? input.value : noFileLabel;
      });
    });
  }

  // multiple file uploads
  if (0 < multipleFileUploads.length) {
    multipleFileUploads.forEach(function (fileUpload) {
      const instructions = fileUpload.querySelector('.gform_drop_instructions');
      const icon = document.createElement('span');
      icon.classList.add('gform_drop_icon');
      icon.classList.add('icon-ajouter-cv');
      instructions.after(icon);
      instructions.textContent = wp.i18n._x(
        'Drag and drop file to upload',
        'drag and drop instruction',
        'vtx'
      );
    });
  }
}

function addFormEvents() {
  const fields = document.querySelectorAll('.gfield');

  if (0 < fields.length) {
    fields.forEach(function (field) {
      if (field.classList.contains('initialized')) {
        return;
      }

      const classesExcluded = [
        'gfield--radio',
        'gfield--checkbox',
        'gfield--consent',
        'gfield--select',
        'gfield--address',
        'gfield--fileupload',
      ];

      for (let i = 0; i < classesExcluded.length; i++) {
        if (field.classList.contains(classesExcluded[i])) {
          return;
        }
      }

      const label = field.querySelector('.gfield_label');
      const input = field.querySelector(
        '.ginput_container input , .ginput_container textarea'
      );

      if (label && input && !input.hasAttribute('placeholder')) {
        field.classList.add('initialized');
        changeFormLabel(label, input);
      }

      if (field.classList.contains('gfield--has-multiple-inputs')) {
        const subfields = field.querySelectorAll('.ginput_complex > span');

        if (0 < subfields.length) {
          subfields.forEach(function (subfield) {
            if (subfield.classList.contains('initialized')) {
              return;
            }
            subfield.classList.add('initialized');

            const sublabel = subfield.querySelector('label');
            const subinput = subfield.querySelector('input, textarea');
            changeFormLabel(sublabel, subinput);
          });
        }
      }
    });
  }
}

$(document).on('gform_post_render', function (e, formID) {
  fieldsAreInitialized = true;
  changeFileUpload(formID);
  addFormEvents();
  initSelects();
  initFacetSelects();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    addFormEvents();
    initSelects();
  }
});

document.addEventListener('facetwp-loaded', function () {
  initFacetSelects();
});
