import * as Focus from '../common/focus';

window.addEventListener('load', function () {
  const toggleSearch = document.querySelector('.toggleSearch');
  const searchModal = document.querySelector('#search-modal');
  const primaryNav = document.querySelector('.nav-primary-wrapper');
  const closeSearch = document.querySelector('#search-close');

  function closeSearchModal() {
    searchModal.classList.remove('opened');
    toggleSearch.setAttribute('aria-expanded', 'false');
    closeSearch.setAttribute('aria-expanded', 'false');

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.untrapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.remove('lockedPosition');
    }

    if (searchModal.classList.contains('search-modal--bar')) {
      primaryNav.classList.remove('hidden');
    }
  }

  function openSearchModal() {
    const openEvent = new Event('opensearchmodal');
    searchModal.classList.add('opened');
    toggleSearch.setAttribute('aria-expanded', 'true');
    closeSearch.setAttribute('aria-expanded', 'true');
    window.dispatchEvent(openEvent);

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.trapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.add('lockedPosition');
    }

    if (searchModal.classList.contains('search-modal--bar')) {
      primaryNav.classList.add('hidden');
    }
  }

  if (toggleSearch && closeSearch) {
    toggleSearch.addEventListener('click', function () {
      if (searchModal.classList.contains('opened')) {
        closeSearchModal();
      } else {
        openSearchModal();
      }
    });

    closeSearch.addEventListener('click', function () {
      if (searchModal.classList.contains('opened')) {
        closeSearchModal();
      }
    });
  }
});
